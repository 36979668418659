.App {
  text-align: left;
  margin-left: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

table {
  width: 70%;
  border-spacing: 2px;
}

table th {
  padding: 6px 10px 5px;
  background-color: #deefff;
  line-height: 120%;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

table td {
  border: 1px #c0c0c0 solid;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
